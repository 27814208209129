import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  withCredentials: true,
});

// const createAxiosResponseInterceptor = async () => {
//   const interceptor = axiosInstance.interceptors.response.use(
//     async (response) => response,
//     async (error) => {
//       // Reject promise if usual error
//       if (error?.response?.status !== 401) {
//         return Promise.reject(error);
//       }
//       /*
//        * When response code is 401, try to refresh the token.
//        * Eject the interceptor so it doesn't loop in case
//        * token refresh causes the 401 response
//        */
//       axiosInstance.interceptors.response.eject(interceptor);
//       let refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
//       return axiosInstance
//         .post("/auth/admin/refreshtoken", { refreshToken: refreshToken })
//         .then((response) => {
//           response = response.data;
//           setAuthToken(response.accessToken);
//           error.response.config.headers.authorization = `Bearer ${response.accessToken}`;
//           return axiosInstance(error.response.config);
//         })
//         .catch(async (error) => {
//           clearAuthToken();
//           await logout();
//           return false;
//         })
//         .finally(createAxiosResponseInterceptor);
//     }
//   );
// };

// createAxiosResponseInterceptor();

// const setAuthToken = async (token) => {
//   axiosInstance.defaults.headers.common.authorization = `Bearer ${token}`;
// };

// const clearAuthToken = async () => {
//   return setAuthToken("");
// };

const login = (authData) => axiosInstance.post("auth/admin/login", authData);

// const refreshToken = () => axiosInstance.post("auth/admin/refreshtoken");

const logout = () => axiosInstance.get("auth/admin/logout");

const createFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(
      key,
      typeof data[key] === "object" && data[key]?.arrayBuffer === undefined
        ? JSON.stringify(data[key])
        : data[key]
    );
  });
  return formData;
};

const createDataResource = (path, isMultipart = false) => ({
  getAll: (
    page = undefined,
    limit = undefined,
    search = undefined,
    filters = undefined
  ) => {
    return axiosInstance.get(path, {
      params: { page, limit, search, filters },
    });
  },
  getOne: (id) => axiosInstance.get(`${path}/${id}`),
  post: (data) => {
    let response;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.post(path, formData, config);
    } else {
      response = axiosInstance.post(path, data);
    }
    return response;
  },
  patch: (data) => {
    let response;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.patch(path, formData, config);
    } else {
      response = axiosInstance.patch(path, data);
    }
    return response;
  },
  delete: (id) => axiosInstance.delete(`${path}/${id}`),
});

const servicesResource = createDataResource("categories", true);

const solutionsResource = createDataResource("solutions", true);

const adminsResource = createDataResource("admins");

const partnersResource = createDataResource("partners", true);

const blogsResource = createDataResource("blogs", true);

const podcastsResource = createDataResource("podcasts", true);

const testimonialsResource = createDataResource("testimonials");

const getNewsletter = () => axiosInstance.get("newsletters");

const getServicesRegistrations = () =>
  axiosInstance.get("servicesRegistrations");

const getPopupInfo = () => axiosInstance.get("popup");

const updatePopupInfo = (data) => {
  const formData = createFormData(data);
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axiosInstance.put(`popup`, formData, config);
};

export default {
  servicesResource,
  adminsResource,
  solutionsResource,
  partnersResource,
  testimonialsResource,
  blogsResource,
  podcastsResource,
  getPopupInfo,
  updatePopupInfo,
  getNewsletter,
  getServicesRegistrations,
  login,
  // refreshToken,
  // setAuthToken,
  // clearAuthToken,
  logout,
};

import * as yup from "yup";

export default [
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "title@en",
            type: "text",
            label: "Title (English)",
            validation: yup.string().required("Required"),
          },
          {
            name: "description@en",
            type: "text",
            options: {
              multiline: true,
              rows: 4,
            },
            label: "Description (English)",
          },
          {
            name: "link",
            type: "text",
            label: "URL (Embed URL ex: https://www.youtube.com/embed/123)",
            validation: yup.string().required("Required"),
          },
          {
            name: "image",
            type: "image",
            label: "Podcast thumbnail",
          },
        ],
      },
      {
        span: 1,
      },
      {
        span: 5,
        fields: [
          {
            name: "title@ar",
            type: "text",
            label: "Title (Arabic)",
            validation: yup.string().required("Required"),
          },
          {
            name: "description@ar",
            type: "text",
            options: {
              multiline: true,
              rows: 4,
            },
            label: "Description (Arabic)",
          },
        ],
      },
    ],
  },
];

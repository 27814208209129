import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { flattenTranslationsObject } from "utils";
import { deepen } from "utils";
import FormField from "../FormField/FormField";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./customFromStyle";

const useStyles = makeStyles(styles);

export default function CustomForm(props) {
  const {
    submitHandler,
    onError,
    loading,
    edit,
    create,
    validationSchema,
    formStructure,
    data,
  } = props;

  const classes = useStyles();

  const resolver = useYupValidationResolver(validationSchema);

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;

  React.useEffect(() => {
    if (data) {
      const temp = flattenTranslationsObject(data);
      Object.keys(temp).forEach((key) => {
        setValue(key, temp[key]);
      });
    }
  }, [data]);

  const onSubmit = (e) => {
    e.preventDefault();

    let values = deepen(getValues(), "@");

    // const fields = formStructure.reduce(
    //   (result, current) => result.concat(current.fields),
    //   []
    // );

    // Object.keys(getValues()).forEach((key) => {
    //   const field = fields.find((field) => field.name === key);
    //   if (field?.getSubmitValue) {
    //     values[key] = field.getSubmitValue(values[key]);
    //   }
    // });

    handleSubmit(submitHandler(values), onError)();
  };

  return (
    <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
      {formStructure?.map((row, rowIndex) => (
        <GridContainer key={rowIndex}>
          {row?.columns?.map((column, columnIndex) => (
            <React.Fragment key={columnIndex}>
              <GridItem xs={12} sm={12} md={column?.span ? column?.span : 12}>
                {column.fields?.map((field, fieldIndex) => (
                  <FormField
                    key={fieldIndex}
                    form={form}
                    type={field.type}
                    name={field.name}
                    label={field.label}
                    options={field.options}
                  />
                ))}
              </GridItem>
            </React.Fragment>
          ))}
          {rowIndex === formStructure?.length - 1 ? (
            <div className={classes.buttonCointainer}>
              <Button
                color="primary"
                type="submit"
                loading={loading}
                style={{ margin: "20px 0" }}
                create={create}
                edit={edit}
                loadFromRight
              />
            </div>
          ) : null}
        </GridContainer>
      ))}
    </form>
  );
}

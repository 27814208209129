// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
// core components
import Table from "components/Table/Table.js";
import React from "react";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);

export default function ServicesRegistrations() {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendRequest(api.getServicesRegistrations());
        setRegistrations(response.data);
      } catch (err) {}
    })();
  }, []);

  const [registrations, setRegistrations] = React.useState([]);

  const [sendRequest, isLoading] = useHttpClient();

  const tableData = registrations.map((registration) => [
    registration.firstName && registration.lastName
      ? registration.firstName + " " + registration.lastName
      : undefined,
    registration.email,
    registration.phone,
  ]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <a href={`mailto:${registrations.map((el) => el.email).join(";")}`}>
          <Button color="primary" round={true}>
            Send Email
          </Button>
        </a>
      </div>

      <GridContainer>
        <GridItem xs={12} sm={6}>
          <Card>
            <CardBody>
              <Loading loading={isLoading}>
                <Table
                  tableHeaderColor="primary"
                  tableHead={["Name", "Email", "Phone"]}
                  tableData={tableData}
                  coloredColls={[3]}
                  colorsColls={["primary"]}
                  pagination
                />
              </Loading>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import defaultAvatar from "assets/img/default-image.png";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./gridListItemStyle";

const useStyles = makeStyles(styles);

export default (props) => {
  const classes = useStyles();

  return (
    <Card product className={classes.cardHover}>
      <CardHeader image className={classes.cardHeaderHover}>
        <Link to={props?.data?.link}>
          <img
            className={classes.cardImage}
            style={props?.data?.image ? { objectPosition: "0 0" } : {}}
            src={props?.data?.image || defaultAvatar}
            alt="..."
          />
        </Link>
      </CardHeader>
      <CardBody>
        <div className={classes.cardHoverUnder}>{props.data.buttons}</div>
        <h4 className={classes.cardProductTitle}>
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            {props.data?.name}
          </a>
        </h4>
        {props.data?.body &&
          props.data?.body?.length > 0 &&
          props.data.body.map((el) => (
            <p className={classes.cardProductDesciprion}>{el}</p>
          ))}
      </CardBody>
      {props.data?.footer && props.data?.footer?.length > 0 && (
        <CardFooter product>
          {props.data?.footer?.map((el) => (
            <div className={`${classes.stats} ${classes.productStats}`}>
              {el}
            </div>
          ))}
        </CardFooter>
      )}
    </Card>
  );
};

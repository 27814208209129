const customInputStyle = {
  root: {
    minHeight: 30,
    width: "100%",
    paddingTop: 27,
    marginBottom: 14,
  },
  inputContanier: {
    paddingBottom: 50,
    border: "1px solid #ccc",
    borderRadius: 5,
    marginTop: 10,
    padding: 10,
    "& #mui-rte-toolbar": {
      borderBottom: "1px solid #ccc",
      marginBottom: 10,
    },
  },
  focus: {
    boxShadow: "0 0 0 3px rgb(66 153 225 / 0.5)",
    borderWidth: 1,
    borderColor: "#90cdf4",
  },
  contentLabel: {
    color: "#AAAAAA",
    fontSize: 14,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    margin: 0,
  },
};

export default customInputStyle;

import api from "services/api";
import * as yup from "yup";

export default [
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "name@en",
            type: "text",
            label: "Name (English)",
            validation: yup.string().required("Required"),
          },
        ],
      },
      { span: 1 },
      {
        span: 5,
        fields: [
          {
            name: "name@ar",
            type: "text",
            label: "Name (Arabic)",
            validation: yup.string().required("Required"),
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "subtitle@en",
            type: "text",
            label: "Subtitle (English)",
          },
        ],
      },
      { span: 1 },
      {
        span: 5,
        fields: [
          {
            name: "subtitle@ar",
            type: "text",
            label: "Subtitle (Arabic)",
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "duration@en",
            type: "text",
            label: "Duration (English)",
            validation: yup.string().required("Required"),
          },
        ],
      },
      { span: 1 },
      {
        span: 5,
        fields: [
          {
            name: "duration@ar",
            type: "text",
            label: "Duration (Arabic)",
            validation: yup.string().required("Required"),
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "image",
            type: "image",
            label: "Solution Image",
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 6,
        fields: [
          {
            name: "summary@en",
            type: "rich-text",
            label: "Summary (English)",
          },
        ],
      },
      {
        span: 6,
        fields: [
          {
            name: "summary@ar",
            type: "rich-text",
            label: "Summary (Arabic)",
            options: {
              dir: "rtl",
            },
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 6,
        fields: [
          {
            name: "detailedDescription@en",
            type: "rich-text",
            label: "Detailed Description (English)",
          },
        ],
      },
      {
        span: 6,
        fields: [
          {
            name: "detailedDescription@ar",
            type: "rich-text",
            label: "Detailed Description (Arabic)",
            options: {
              dir: "rtl",
            },
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "descriptionImage",
            type: "image",
            label: "Description Image",
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 6,
        fields: [
          {
            name: "process@en",
            type: "rich-text",
            label: "Process (English)",
          },
        ],
      },
      {
        span: 6,
        fields: [
          {
            name: "process@ar",
            type: "rich-text",
            label: "Process (Arabic)",
            options: {
              dir: "rtl",
            },
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "processImage",
            type: "image",
            label: "Process Image",
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "categoryId",
            type: "select",
            label: "Service Category",
            options: {
              getData: api.servicesResource.getAll,
              rawDataConvertor: (data) =>
                data?.map((entry) => ({
                  value: entry.id,
                  name: entry.name?.en,
                })),
            },
            validation: yup.mixed().required("Required"),
          },
        ],
      },
      {
        span: 1,
      },
      {
        span: 5,
        fields: [
          {
            name: "faceToFace",
            type: "switch",
            label: "Is it face to face?",
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "redirectButtonName@en",
            type: "text",
            label: "Redirect Button Name (English)",
          },
        ],
      },
      {
        span: 1,
      },
      {
        span: 5,
        fields: [
          {
            name: "redirectButtonName@ar",
            type: "text",
            label: "Redirect Button Name (Arabic)",
          },
        ],
      },
    ],
  },
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "redirectUrl",
            type: "text",
            label: "Redirect to (Must start with http/https)",
          },
        ],
      },
    ],
  },
];

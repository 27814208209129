import DataList from "components/DataList/DataList";
import FormPage from "components/Forms/FormPage/FormPage";
import React from "react";
import api from "services/api";
import adminsFormStructure from "views/Admins/adminsFormStructure";
import adminIcon from "@material-ui/icons/Lock";

// appear in breadcrumbs and page headers
const listPageName = "admins";
const createPageName = "createAdmin";
const editPageName = "editAdmin";

// appears on alerts; example: Entity created successfully
const entityName = "admin";

const layout = "/admin";

const path = "admins";

const formStructure = adminsFormStructure;

const dataResource = api.adminsResource;

const tableHead = ["id", "name", "email"];

// converts data fetched from backend to tableData
const rawDataConvertor = (listData) =>
  listData?.map((entry) => {
    return [entry.id, entry.name, entry.email];
  });

const getName = (data) => data?.name;

export default {
  path: `/${path}`,
  name: listPageName,
  icon: adminIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      tableHead={tableHead}
      rawDataConvertor={rawDataConvertor}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      getName={getName}
    />
  ),
  layout: layout,
  randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          getName={getName}
          create
        />
      ),
      layout: layout,
      randomKey: true,
    },
    {
      path: `/${path}/edit/:id`,
      name: editPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          getName={getName}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
    },
  ],
};

import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./autocompleteInputStyle.js";

const useStyles = makeStyles(styles);

export default function AutocompleteInput(props) {
  const classes = useStyles();
  const {
    options,
    id,
    formControlProps,
    error,
    helperText,
    labelText,
    selectedValue,
    multiple,
    ...rest
  } = props;

  const [value, setValue] = React.useState(multiple ? [] : null);

  React.useEffect(() => {
    if (selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue]);

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underline]: true,
  });

  const inputClasses = classNames({
    [classes.input]: true,
  });

  const labelClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRoot]: true,
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <Autocomplete
        openOnFocus
        blurOnSelect
        clearOnEscape
        clearOnBlur
        autoHighlight
        options={options}
        value={value}
        id={id}
        classes={{
          inputRoot: underlineClasses,
          input: inputClasses,
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autocomplete: "no",
                // form: {
                //   autocomplete: "off",
                // },
              }}
              label={labelText}
              InputLabelProps={{
                className: labelClasses,
              }}
              margin="normal"
            />
          );
        }}
        multiple={multiple}
        {...rest}
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

Autocomplete.propTypes = {
  options: PropTypes.array,
  labelText: PropTypes.node,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.node,
};

import * as yup from "yup";

export default [
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "title",
            type: "text",
            label: "Title",
            validation: yup.string().required("Required"),
          },
          {
            name: "date",
            type: "date",
            label: "Date",
          },
          {
            name: "language",
            type: "select",
            label: "Blog Language",
            options: {
              data: [
                { name: "Arabic", value: "ar" },
                { name: "English", value: "en" },
              ],
            },
            validation: yup.mixed().required("Required"),
          },
          {
            name: "image",
            type: "image",
            label: "Blog Image",
          },
        ],
      },
    ],
  },
  {
    row: 2,
    columns: [
      {
        span: 12,
        fields: [
          {
            name: "content",
            type: "rich-text",
            label: "Content",
            options: {
              getDirection: (watch) =>
                watch("language") === "ar" ? "rtl" : "ltr",
            },
          },
        ],
      },
    ],
  },
];

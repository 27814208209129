import api from "services/api";
import * as yup from "yup";

export default [
  {
    row: 1,
    singleColumn: true,
    columns: [
      {
        span: 12,
        fields: [
          {
            name: "name",
            type: "text",
            label: "Name",
            validation: yup.string().required("Required"),
          },
          {
            name: "title",
            type: "text",
            label: "Title",
          },
          {
            name: "description",
            type: "text",
            options: {
              multiline: true,
              rows: 4,
            },
            label: "Testimonial",
            validation: yup.string().required("Required"),
          },
          {
            name: "solutionId",
            type: "select",
            label: "Solution",
            options: {
              getData: api.solutionsResource.getAll,
              rawDataConvertor: (data) =>
                data?.map((entry) => ({
                  value: entry.id,
                  name: entry.name?.en,
                })),
            },
          },
        ],
      },
    ],
  },
];

import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal.js";
import PropTypes from "prop-types";
import React from "react";
import { Transition } from "react-transition-group";
import style from "./confirmModalStyle.js";
import { useHistory } from "react-router";

const useStyles = makeStyles(style);

export default function ConfrimModal(props) {
  const {
    isOpen,
    onClose,
    onConfirm,
    loading,
    headerMessage,
    successHeaderText,
    confirmMessage,
    errorMessage,
    successMessage,
    activeScreen,
    confirmButtonColor,
    confirmButtonText,
    refreshOnSuccess,
    cancelButtonText,
    redirectPath,
  } = props;

  const classes = useStyles();

  const history = useHistory();

  let modalConent = <div />;

  if (activeScreen === "question") {
    modalConent = (
      <div>
        <h4 className={classes.modalHeader}>{headerMessage}</h4>
        <div className={classes.modalBody}>
          <p>{confirmMessage}</p>
        </div>
        <div className={classes.modalFooter}>
          <Button
            color="transparent"
            className={classes.button + " " + classes.cancel}
            onClick={onClose}
            round
          >
            {cancelButtonText}
          </Button>
          <Button
            color={confirmButtonColor}
            className={classes.button}
            onClick={onConfirm}
            loading={loading}
            round
          >
            {confirmButtonText}
          </Button>
        </div>
      </div>
    );
  } else if (activeScreen === "success") {
    //success
    modalConent = (
      <div>
        <h4 className={classes.modalHeader + " " + classes.success}>
          {successHeaderText}
        </h4>
        <div className={classes.modalBody}>
          <p>{successMessage}</p>
        </div>
        <div className={classes.modalFooter}>
          <Button
            color="transparent"
            className={classes.button + " " + classes.cancel}
            onClick={() => {
              onClose();
              if (refreshOnSuccess) history.go(0);
              else if (redirectPath)
                history.push({
                  pathname: redirectPath,
                });
            }}
            round
          >
            OK
          </Button>
        </div>
      </div>
    );
  } else if (activeScreen === "error") {
    //error
    modalConent = (
      <div>
        <h4 className={classes.modalHeader + " " + classes.error}>Error</h4>
        <div className={classes.modalBody}>
          <p>{errorMessage}</p>
        </div>
        <div className={classes.modalFooter}>
          <Button
            color="transparent"
            className={classes.button + " " + classes.cancel}
            onClick={onClose}
            round
          >
            OK
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Transition mountOnEnter unmountOnExit in={isOpen} timeout={300}>
      <CustomModal
        open={isOpen}
        onClose={() => {
          onClose();
          if (refreshOnSuccess && activeScreen === "success") history.go(0);
        }}
        modalRootClass={classes.modalRoot}
      >
        {modalConent}
      </CustomModal>
    </Transition>
  );
}

ConfrimModal.defaultProps = {
  isOpen: false,
  loading: false,
  headerMessage: "Confirmation",
  confirmMessage: "Are you sure?",
  errorMessage: "Something went wrong",
  successMessage: "Action performed successfully",
  activeScreen: "question",
  confirmButtonText: "Confirm",
  confirmButtonColor: "primary",
  cancelButtonText: "Cancel",
  successHeaderText: "Success",
  refreshOnSuccess: false,
};

ConfrimModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
  headerMessage: PropTypes.string,
  confirmMessage: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  cancelButtonText: PropTypes.string,
  successHeaderText: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  activeScreen: PropTypes.string,
  refreshOnSuccess: PropTypes.bool,
  redirectPath: PropTypes.string,
};

import AutocompleteInput from "components/AutocompleteInput/AutocompleteInput";
import CustomInput from "components/CustomInput/CustomInput";
import RichTextInput from "components/CustomInput/RichTextInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import SelectInput from "components/SelectInput/SelectInput";
import React from "react";
import { Controller } from "react-hook-form";
import Fade from "react-reveal/Fade";
import { toast } from "react-toastify";
import ImageUpload from "components/CustomUpload/ImageUpload";

export default function FormField(props) {
  const {
    type,
    name,
    label,
    form,
    options,
    getShowCondition,
    dependencyList,
    rules,
  } = props;

  const { control, setValue, errors, watch } = form;

  const [data, setData] = React.useState([]);
  const [isShown, setIsShown] = React.useState(false);

  React.useEffect(() => {
    let temp = [];
    const compare = (a, b) => {
      const temp1 =
        typeof a[options.sortBy] === "string"
          ? a[options.sortBy].toUpperCase()
          : a[options.sortBy];

      const temp2 =
        typeof b[options.sortBy] === "string"
          ? b[options.sortBy].toUpperCase()
          : b[options.sortBy];

      if (temp1 < temp2) {
        return -1;
      }
      if (temp1 > temp2) {
        return 1;
      }
      return 0;
    };
    if (options?.data) {
      temp = [...options.data];
      if (options?.sortBy !== undefined) {
        setData(temp.sort(compare));
      } else {
        setData(temp);
      }
    } else if (options?.getData) {
      (async () => {
        try {
          const resp = (await options.getData()).data;
          if (options.rawDataConvertor) {
            temp = [...options.rawDataConvertor(resp.data)];
          } else {
            temp = [...resp.data];
          }
          if (options?.sortBy !== undefined) {
            setData(temp.sort(compare));
          } else {
            setData(temp);
          }
        } catch (err) {
          toast.error(`An error occurred while loading ${label}`);
        }
      })();
    }
  }, [options]);

  React.useEffect(() => {
    if (getShowCondition && getShowCondition()) {
      setIsShown(true);
    } else if (getShowCondition && !getShowCondition()) {
      setIsShown(false);
      setValue(name, undefined);
    }
  }, [...dependencyList]);

  const handleChange = (prop) => (event) => setValue(prop, event.target.value);

  const field = (
    <Controller
      render={(props) => {
        return type === "text" ||
          type === "number" ||
          type === "date" ||
          type === "datetime-local" ? (
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: handleChange(name),
              value: type === "date" ? props.value?.split("T")[0] : props.value,
              type,
              multiline: options?.multiline,
              rows: options?.multiline ? (options.rows ? options.rows : 5) : 1,
            }}
            labelText={label}
            error={!!errors[name]?.message}
            helperText={errors[name]?.message}
            name={name}
          />
        ) : type === "select" ? (
          <SelectInput
            id={name}
            labelText={label}
            placeholder={label}
            error={!!errors[name]?.message}
            errorMessage={errors[name]?.message}
            data={data}
            selectedValue={props.value}
            onSelect={handleChange(name)}
          />
        ) : type === "autocomplete" ? (
          <AutocompleteInput
            getOptionDisabled={
              options.multiple
                ? (option) =>
                    props.value?.some((selectedEl) => {
                      if (typeof option === "object")
                        return selectedEl.id === option.id;
                      else return selectedEl === option;
                    })
                : undefined
            }
            formControlProps={{ fullWidth: true }}
            options={data}
            id={name}
            labelText={label}
            selectedValue={props.value}
            onChange={(_, newValue) => {
              setValue(name, newValue);
            }}
            error={!!errors[name]?.message}
            helperText={errors[name]?.message}
            {...options}
          />
        ) : type === "switch" ? (
          <CustomSwitch
            value={props.value}
            onChange={() => setValue(name, !props.value)}
            labelText={label}
          />
        ) : type === "rich-text" ? (
          <RichTextInput
            value={props.value}
            setValue={(value) => setValue(name, value)}
            labelText={label}
            onChange={(editorState) => {
              setValue(
                name?.split("@")?.length > 1
                  ? `${name?.split("@")[0]}Text@${name?.split("@")[1]}`
                  : `${name}Text`,
                editorState.getCurrentContent().getPlainText()
              );
            }}
            dir={
              options?.getDirection
                ? options?.getDirection(watch)
                : options?.dir
                ? options?.dir
                : "ltr"
            }
            {...options}
          />
        ) : type === "image" ? (
          <ImageUpload
            label={label}
            file={props.value}
            setFile={(file) => setValue(name, file)}
            addButtonProps={{
              color: "primary",
              round: true,
            }}
            changeButtonProps={{
              color: "primary",
              round: true,
            }}
            removeButtonProps={{
              color: "danger",
              round: true,
            }}
          />
        ) : null;
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );
  return getShowCondition === undefined ? (
    field
  ) : (
    <Fade unmountOnExit mountOnEnter collapse when={isShown} duration={700}>
      {field}
    </Fade>
  );
}

FormField.defaultProps = {
  dependencyList: [],
};

/* 
- Component props are:

  1. name: field name in form
  2. type: field type
  3. label: displayed field label and placeholder
  4. options: options object of the corresponding type
  5. validation: validation schema for the field
  6. getSubmitValue: function that converts field values before submitting the form
  7. getShowCondition: function that returns a boolean to show the field when true
  8. dependencyList: dependency list that updates visibility status 

  
- type values are:

1. text: 
  uses CustomInput component with type text
  options => {
    multiline: bool
    rows: number
  }

2. number:
  uses CustomInput component with type number

3. date: 
  uses CustomInput component with type date

4. datetime-local: 
  uses CustomInput component with type datetime-local

5. select: 
  uses SelectInput component
  options => {
    data: select options array
    getData: function that populates data prop in SelectInput ,,,, (either send data prop or getData) 
    rawDataConvertor: map to convert fetched values from getData to [{name: "example", value: 0}] (optional)
  }

6. autocomplete: 
  uses AutocompleteInput component
  options => {
    data: autocomplete options array
    getData: function that populates options prop in AutocompleteInput ,,,, (either send data prop or getData) 
    getOptionLabel: same as AutocompleteInput *required
    ... whatever the AutocompleteInput takes 
  }

7. switch:
  uses CustomSwitch component

8. rich-text:
  uses RichTextInput component
  options => {
    dir: ltr/rtl (ltr by default)
    getDirection: function to determine direction. Takes watch as argument (should return ltr or rtl)
  }

9. image: 
  uses ImageUpload component

*/

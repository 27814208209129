import * as yup from "yup";

export default [
  {
    row: 1,
    singleColumn: true,
    columns: [
      {
        span: 12,
        fields: [
          {
            name: "name",
            type: "text",
            label: "Name",
            validation: yup.string().required("Required"),
          },
          // {
          //   name: "link",
          //   type: "text",
          //   label: `Link (must start with "http://" or "https://")`,
          //   validation: yup.string().required("Required"),
          // },
          {
            name: "image",
            type: "image",
            label: "Partner Image",
          },
        ],
      },
    ],
  },
];

import LoginPage from "views/LoginPage/LoginPage";
import adminsRoutes from "./entities/adminsRoutes";
import partnersRoutes from "./entities/partnersRoutes";
import servicesRoutes from "./entities/servicesRoutes";
import solutionsRoutes from "./entities/solutionsRoutes";
import testimonialsRoutes from "./entities/testimonialsRoutes";
import Newsletter from "views/Newsletter/Newsletter";
import NewsletterIcon from "@material-ui/icons/Email";
import podcastsRoutes from "./entities/podcastsRoutes";
import blogsRoutes from "./entities/blogsRoutes";
import LaunchIcon from "@material-ui/icons/Launch";
import Popup from "views/Popup/Popup";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ServicesRegistrations from "views/ServicesRegistrations/ServicesRegistrations";

var routes = [
  {
    hidden: true,
    path: "/login",
    name: "Login Page",
    rtlName: "هعذاتسجيل الدخول",
    rtlMini: "هعذا",
    component: LoginPage,
    layout: "/auth",
  },
  {
    path: "/popup",
    name: "Popup",
    icon: LaunchIcon,
    component: Popup,
    layout: "/admin",
  },
  { ...servicesRoutes },
  {
    path: "/service-registrations",
    name: "Services Registrations",
    icon: AssignmentIcon,
    component: ServicesRegistrations,
    layout: "/admin",
  },
  { ...solutionsRoutes },
  { ...blogsRoutes },
  { ...podcastsRoutes },
  { ...partnersRoutes },
  { ...testimonialsRoutes },
  {
    path: "/newsletter",
    name: "Newsletter",
    icon: NewsletterIcon,
    component: Newsletter,
    layout: "/admin",
  },
  { ...adminsRoutes },
];

export default routes;

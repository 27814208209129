import DataList from "components/DataList/DataList";
import FormPage from "components/Forms/FormPage/FormPage";
import React from "react";
import api from "services/api";
import solutionsFormStructure from "views/Solutions/solutionsFormStructure";
import SchoolIcon from "@material-ui/icons/School";

// appear in breadcrumbs and page headers
const listPageName = "Solutions";
const createPageName = "Create Solution";
const editPageName = "Edit Solution";

// appears on alerts; example: Entity created successfully
const entityName = "Solution";

const layout = "/admin";

const path = "solutions";

const formStructure = solutionsFormStructure;

const dataResource = api.solutionsResource;

const getCardBody = (data) => [data?.summaryText?.en];
const getCardFooter = (data) => [data?.category?.name?.en, data?.duration?.en];

export default {
  path: `/${path}`,
  name: listPageName,
  icon: SchoolIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      type="grid"
      getCardBody={getCardBody}
      getCardFooter={getCardFooter}
    />
  ),
  layout: layout,
  randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
    },
    {
      path: `/${path}/edit/:id`,
      name: editPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
    },
  ],
};

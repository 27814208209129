import ChatIcon from "@material-ui/icons/Chat";
import DataList from "components/DataList/DataList";
import FormPage from "components/Forms/FormPage/FormPage";
import React from "react";
import api from "services/api";
import testimonialsFormStructure from "views/Testimonials/testimonialsFormStructure";

// appear in breadcrumbs and page headers
const listPageName = "Testimonials";
const createPageName = "Create Testimonial";
const editPageName = "Edit Testimonial";

// appears on alerts; example: Entity created successfully
const entityName = "Testimonial";

const layout = "/admin";

const path = "testimonials";

const formStructure = testimonialsFormStructure;

const dataResource = api.testimonialsResource;

const tableHead = ["ID", "Name", "title", "Testimonial"];

// converts data fetched from backend to tableData
const rawDataConvertor = (listData) =>
  listData?.map((entry) => {
    return [entry.id, entry.name, entry.title, entry.description];
  });

const getName = (data) => data?.name + "'s testimonial";

export default {
  path: `/${path}`,
  name: listPageName,
  icon: ChatIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      tableHead={tableHead}
      rawDataConvertor={rawDataConvertor}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      getName={getName}
    />
  ),
  layout: layout,
  randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
          getName={getName}
        />
      ),
      layout: layout,
      randomKey: true,
    },
    {
      path: `/${path}/edit/:id`,
      name: editPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
          getName={getName}
        />
      ),
      layout: layout,
      randomKey: true,
    },
  ],
};

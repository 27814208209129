import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import React from "react";
import styles from "./customSwitchStyle";

const useStyles = makeStyles(styles);

export default function CustomSwitch(props) {
  const classes = useStyles();

  const { value, onChange, labelText, rootStyle, ...rest } = props;

  const [valueState, setValueState] = React.useState("");

  React.useEffect(() => {
    setValueState(value);
  }, [value]);

  return (
    <div style={rootStyle} className={classes.root}>
      {labelText && <div className={classes.label}>{labelText}</div>}
      <div className={classes.switchContainer}>
        <Switch
          checked={valueState}
          onChange={onChange}
          classes={{
            switchBase: classes.switchBase,
            checked: classes.switchChecked,
            thumb: classes.switchIcon,
            track: classes.switchBar,
          }}
          inputProps={{ "aria-label": "secondary checkbox" }}
          {...rest}
        />
      </div>
    </div>
  );
}

CustomSwitch.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  labelText: PropTypes.string,
  rootStyle: PropTypes.object,
};

const popupStyle = (theme) => ({
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    padding: "10px 30px",
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    margin: "0px -20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  icon: {
    marginRight: 15,
    fontSize: 26,
    color: "#6f7f88",
  },
  form: {
    padding: 20,
    paddingBottom: 0,
    [theme.breakpoints.down("xs")]: {
      padding: 20,
      paddingBottom: 0,
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
});

export default popupStyle;

import * as yup from "yup";

export default [
  {
    row: 1,
    singleColumn: true,
    columns: [
      {
        span: 12,
        fields: [
          {
            name: "email",
            type: "text",
            label: "Email",
            validation: yup
              .string()
              .required("Required")
              .email("Please enter a valid email"),
          },
          {
            name: "name",
            type: "text",
            label: "Name",
            validation: yup.string().required("Required"),
          },
          {
            name: "password",
            type: "text",
            label: "Password",
            validation: yup.string().min(8, "Must be 8 characters or more"),
          },
        ],
      },
    ],
  },
];

import { Hidden } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import React from "react";
import { AuthContext } from "shared/context/auth-context";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const auth = React.useContext(AuthContext);

  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const { rtlActive } = props;
  // const searchButton =
  //   classes.top +
  //   " " +
  //   classes.searchButton +
  //   " " +
  //   classNames({
  //     [classes.searchRTL]: rtlActive,
  //   });
  const dropdownItem = classNames(classes.dropdownItem, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const handleLogout = () => auth.logout();

  const dropdownItems = (
    <MenuList role="menu">
      <Divider light />
      <MenuItem onClick={handleLogout} className={dropdownItem}>
        {rtlActive ? "الخروج" : "Log out"}
      </MenuItem>
    </MenuList>
  );

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        {/* <a
            className={classes.logoutNavLink}
            href={`${process.env.REACT_APP_SERVER_URL}/auth/admin/logout`}
            onClick={handleLogout}
          >
            Log out
          </a> */}
        <Button
          color="transparent"
          aria-label="Person"
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <div className={classes.buttonChildrenContainer}>
            <AccountCircleIcon
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <div className={classes.nameContainer}>
              <div className={classes.name}>{auth?.userData?.name}</div>
              <div className={classes.role}>Admin</div>
            </div>
          </div>
        </Button>
        <Hidden smDown>
          <Popper
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    {dropdownItems}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Hidden>
        <Hidden mdUp>
          {dropdownItems}
          <Divider light />
        </Hidden>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};

import DataList from "components/DataList/DataList";
import FormPage from "components/Forms/FormPage/FormPage";
import React from "react";
import api from "services/api";
import servicesFormStructure from "views/Services/servicesFormStructure";
import CategoryIcon from "@material-ui/icons/Category";

// appear in breadcrumbs and page headers
const listPageName = "Services";
const createPageName = "Create Service";
const editPageName = "Edit Service";

// appears on alerts; example: Entity created successfully
const entityName = "Service";

const layout = "/admin";

const path = "services";

const formStructure = servicesFormStructure;

const dataResource = api.servicesResource;

const tableHead = ["ID", "Name", "Solution (Yes/ No)"];

// converts data fetched from backend to tableData
const rawDataConvertor = (listData) =>
  listData?.map((entry) => {
    return [entry.id, entry.name?.en, entry.isSolution ? "Yes" : "No"];
  });

export default {
  path: `/${path}`,
  name: listPageName,
  icon: CategoryIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      tableHead={tableHead}
      rawDataConvertor={rawDataConvertor}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
    />
  ),
  layout: layout,
  randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
    },
    {
      path: `/${path}/edit/:id`,
      name: editPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
    },
  ],
};

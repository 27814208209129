import enums from "enums";
import * as yup from "yup";

export const getDateString = (d, locale = "de", includeTime = false) => {
  var date = new Date(d);

  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    (includeTime ? date.toLocaleTimeString(locale, timeOptions) + ", " : "") +
    date.toLocaleDateString(locale, dateOptions)
  );
};

export const flatten = (arr) => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
};

export const deepen = (obj, splitBy = ".") => {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split(splitBy);

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
};

export const flattenTranslationsObject = (obj) => {
  let processedObj = {};

  Object.keys(obj).forEach((key) => {
    if (
      obj[key] &&
      typeof obj[key] === "object" &&
      obj[key].hasOwnProperty("en")
    ) {
      Object.keys(obj[key]).forEach((language) => {
        processedObj[key + "@" + language] = obj[key][language];
      });
    } else {
      processedObj[key] = obj[key];
    }
  });

  return processedObj;
};

export const setLocaleCookie = (lang) => {
  document.cookie = `i18next=${lang}; expires=Fri, 31 Dec 2099 23:59:59 GMT; path=/`;
};

export const createValidationSchema = (formStructure) => {
  let validationObj = {};
  if (formStructure) {
    for (let rowIndex = 0; rowIndex < formStructure.length; rowIndex++) {
      for (
        let columnIndex = 0;
        columnIndex < formStructure[rowIndex].columns.length;
        columnIndex++
      ) {
        for (
          let fieldIndex = 0;
          fieldIndex <
          formStructure[rowIndex].columns[columnIndex].fields?.length;
          fieldIndex++
        ) {
          validationObj[
            formStructure[rowIndex].columns[columnIndex].fields[fieldIndex].name
          ] =
            formStructure[rowIndex].columns[columnIndex].fields[
              fieldIndex
            ].validation;
        }
      }
    }
  }
  return yup.object(validationObj);
};

export const setObjectProp = (obj, path, value) => {
  const pList = path.split(".");
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
};

export const setPageSettingsProp = (propPath, value) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  if (settings === null) {
    settings = {};
  }
  setObjectProp(settings, propPath, value);
  localStorage.setItem("settings", JSON.stringify(settings));
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getHelperMarker = (status, isNew) => {
  let imgName = "";

  if (isNew) imgName = "new-";

  if (status === enums.InvitationStatus.PENDING) imgName += "orange-person-pin";
  else if (status === enums.InvitationStatus.ACCEPTED)
    imgName += "green-person-pin";
  else if (status === enums.InvitationStatus.REJECTED)
    imgName += "red-person-pin";
  else imgName += "blue-person-pin";

  return require(`assets/img/icons/${imgName}.png`);
};

export const replaceUrlSearchParam = (currentSearch, param, value) => {
  let search = new URLSearchParams(currentSearch);
  search.set(param, value);

  return search?.toString();
};

export const getDateTimeString = (isoString) => {
  const date = new Date(isoString);
  const splitDate = date
    .toLocaleString("de-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    })
    .split(", ");

  return (
    splitDate[0].split(".").reverse().join("-") +
    "T" +
    splitDate[1].substr(0, 5)
  );
};

export const checkIfHexadecimal = (str) => {
  const regexp = /^[0-9a-fA-F]+$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
};

import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomForm from "components/Forms/CustomForm/CustomForm";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { createValidationSchema } from "utils";
import styles from "./formPageStyle";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

export default function FormPage(props) {
  const {
    create,
    edit,
    entityName,
    path,
    dataResource,
    formStructure,
    getName,
  } = props;

  const validationSchema = createValidationSchema(formStructure);

  const classes = useStyles();

  const [data, setData] = React.useState(null);

  const [sendRequest, isLoading] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const history = useHistory();

  React.useEffect(() => {
    if (edit) {
      const id = window.location.pathname.split("/").pop().trim();
      if (id) {
        (async () => {
          try {
            const response = await sendGetRequest(dataResource.getOne(id));
            setData(response);
          } catch (err) {
            toast.error("An error has occurred");
            history.push({
              pathname: `/admin/${path}`,
            });
          }
        })();
      } else {
        toast.error("An error has occurred");
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    }
  }, [edit]);

  const createHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.post(values));
      toast.success(`${entityName} created successfully`);
      history.push({
        pathname: `/admin/${path}`,
      });
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(dataResource.patch(values));
      toast.success(`${entityName} edited successfully`);
      history.push({
        pathname: `/admin/${path}`,
      });
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and try again");
  };

  return (
    <div>
      <Breadcrumbs />
      <GridContainer>
        <GridItem xs={12} sm={12} md={formStructure[0]?.singleColumn ? 6 : 12}>
          <Card>
            <CardBody>
              <div className={classes.formHeader}>
                {create ? (
                  <AddIcon className={classes.icon} />
                ) : (
                  <EditIcon className={classes.icon} />
                )}
                {edit ? (
                  isGetLoading ? (
                    <Skeleton variant="rect" height={25} width={250} />
                  ) : (
                    `Edit ${getName(data)}`
                  )
                ) : (
                  `Create ${entityName}`
                )}
              </div>
              <Loading loading={isGetLoading} style={{ height: "60vh" }}>
                <CustomForm
                  formStructure={formStructure}
                  submitHandler={create ? createHandler : editHandler}
                  onError={onError}
                  loading={isLoading}
                  validationSchema={validationSchema}
                  data={data}
                  create={create}
                  edit={edit}
                />
              </Loading>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

FormPage.defaultProps = {
  getName: (data) => {
    const name = data ? data.name?.en : "";
    return name;
  },
};

FormPage.propTypes = {
  getName: PropTypes.func,
  entityName: PropTypes.string,
  dataResource: PropTypes.object,
  path: PropTypes.string,
  edit: PropTypes.bool,
  create: PropTypes.bool,
  formStructure: PropTypes.object,
};

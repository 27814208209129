import {
  blackColor,
  cardTitle,
  hexToRgb,
  dangerColor,
} from "assets/jss/material-dashboard-pro-react";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const selectInputStyle = (theme) => ({
  ...customDropdownStyle(theme),
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[0],
    },
  },
  mrAuto: {
    marginRight: "auto",
  },
  mlAuto: {
    marginLeft: "auto",
  },
});

export default selectInputStyle;

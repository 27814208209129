import DataList from "components/DataList/DataList";
import FormPage from "components/Forms/FormPage/FormPage";
import React from "react";
import api from "services/api";
import blogsFormStructure from "views/Blogs/blogsFormStructure";
import AssignmentIcon from "@material-ui/icons/Assignment";

// appear in breadcrumbs and page headers
const listPageName = "Blogs";
const createPageName = "Create Blog";
const editPageName = "Edit Blog";

// appears on alerts; example: Entity created successfully
const entityName = "Blog";

const layout = "/admin";

const path = "blogs";

const formStructure = blogsFormStructure;

const dataResource = api.blogsResource;

const getCardBody = (data) => [data?.contentText];
const getName = (data) => data?.title;

export default {
  path: `/${path}`,
  name: listPageName,
  icon: AssignmentIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      type="grid"
      getCardBody={getCardBody}
      getName={getName}
    />
  ),
  layout: layout,
  randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          getName={getName}
          create
        />
      ),
      layout: layout,
      randomKey: true,
    },
    {
      path: `/${path}/edit/:id`,
      name: editPageName,
      render: () => (
        <FormPage
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          getName={getName}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
    },
  ],
};

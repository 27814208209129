import * as yup from "yup";

export default [
  {
    row: 1,
    columns: [
      {
        span: 5,
        fields: [
          {
            name: "name@en",
            type: "text",
            label: "Name (English)",
            validation: yup.string().required("Required"),
          },
          {
            name: "brief@en",
            type: "text",
            options: {
              multiline: true,
              rows: 4,
            },
            label: "Brief (English)",
          },
          {
            name: "description@en",
            type: "rich-text",
            label: "Description (English)",
          },
          {
            name: "isSolution",
            type: "switch",
            label: "Is it a solution?",
          },
          {
            name: "image",
            type: "image",
            label: "Service Image",
          },
        ],
      },
      {
        span: 1,
      },
      {
        span: 5,
        fields: [
          {
            name: "name@ar",
            type: "text",
            label: "Name (Arabic)",
            validation: yup.string().required("Required"),
          },
          {
            name: "brief@ar",
            type: "text",
            options: {
              multiline: true,
              rows: 4,
            },
            label: "Brief (Arabic)",
          },
          {
            name: "description@ar",
            type: "rich-text",
            label: "Description (Arabic)",
            options: {
              dir: "rtl",
            },
          },
        ],
      },
    ],
  },
];

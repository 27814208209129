import { makeStyles } from "@material-ui/core/styles";
import Search from "@material-ui/icons/Search";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loading from "components/Loading/Loading";
import Table from "components/Table/Table.js";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./dataListStyle";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import { toast } from "react-toastify";
import GridList from "components/GridList/GridList";
import GridListItem from "components/GridList/GridListItem/GridListItem";

const useStyles = makeStyles(styles);

export default function DataList(props) {
  const classes = useStyles();

  const {
    dataResource,
    rawDataConvertor,
    entityName,
    path,
    createUrl,
    tableHead,
    actions,
    getName,
    type,
    getCardBody,
    getCardFooter,
    getImage,
  } = props;

  const history = useHistory();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [rawData, setRawData] = React.useState({ data: [], count: 0 });
  const [searchQuery, setSearchQuery] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [deleteItemId, setDeleteItemId] = React.useState(null);
  const [activeScreen, setActiveScreen] = React.useState("question");
  const [dataState, setDataState] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState(true);
  const [itemsPerPage] = React.useState(type === "table" ? 10 : 9);

  const [sendGetRequest] = useHttpClient();
  const [sendDeleteRequest, isDeleteLoading] = useHttpClient();

  React.useEffect(() => {
    (async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        let page = searchParams.get("page");

        if (page) {
          setCurrentPage(parseInt(page));
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
        }

        const { count, data } = await sendGetRequest(
          dataResource.getAll(page, itemsPerPage, searchQuery)
        );
        setRawData({ data, count });
        if (type === "grid") {
          setDataState(data || []);
        } else {
          setDataState(rawDataConvertor(data) || []);
        }

        setLoadingData(false);
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  React.useEffect(() => {
    if (rawData?.count > 0 && !rawData?.data?.length) {
      history.push({
        pathname: window.location.pathname,
        search: `?page=${Math.ceil(rawData?.count / itemsPerPage)}`,
      });
    }
  }, [rawData]);

  const searchButton = classes.top + " " + classes.searchButton;

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      setLoadingData(true);
      const { count, data } = await sendGetRequest(
        dataResource.getAll(1, itemsPerPage, searchQuery)
      );
      setCurrentPage(1);
      setRawData({ data, count });
      if (type === "grid") {
        setDataState(data || []);
      } else {
        setDataState(rawDataConvertor(data) || []);
      }
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
    }
  };

  const paginate = async (pageNumber) => {
    history.push({
      pathname: window.location.pathname,
      search: `?page=${pageNumber}`,
    });

    setCurrentPage(pageNumber);
    try {
      setLoadingData(true);
      const { data, count } = await sendGetRequest(
        dataResource.getAll(pageNumber, itemsPerPage, searchQuery)
      );
      setRawData({ data, count });
      if (type === "grid") {
        setDataState(data || []);
      } else {
        setDataState(rawDataConvertor(data) || []);
      }
      setLoadingData(false);
    } catch (err) {
      setLoadingData(false);
    }
  };

  const deleteItem = async () => {
    try {
      await sendDeleteRequest(dataResource.delete(deleteItemId));
      setActiveScreen("success");
    } catch (err) {
      setActiveScreen("error");
    }
  };

  let tableData = [...dataState];

  if (actions && tableData.length && type === "table") {
    tableData = tableData.map((row, index) => {
      return [
        ...row,
        <div style={{ display: "flex" }}>
          {actions.edit ? (
            <Link to={`/admin/${path}/edit/${rawData?.data[index]?.id}`}>
              <Button edit color="white" textColor="primary" />
            </Link>
          ) : null}
          {actions.delete ? (
            <Button
              deleteButton
              onClick={() => {
                setDeleteItemId(rawData?.data[index]?.id);
                setModalOpen(true);
              }}
              color="white"
              textColor="danger"
            />
          ) : null}
        </div>,
      ];
    });
  }

  let gridListData = [...dataState];

  if (actions && gridListData.length && type === "grid") {
    gridListData = gridListData.map((el, index) => {
      return {
        image: getImage(el),
        name: getName(el),
        body: [...getCardBody(el)],
        footer: [...getCardFooter(el)],
        link: `/admin/${path}/edit/${rawData?.data[index]?.id}`,
        buttons: (
          <div style={{ display: "flex" }}>
            {actions.edit ? (
              <Link to={`/admin/${path}/edit/${rawData?.data[index]?.id}`}>
                <Button
                  className={classes.actionButton}
                  justIcon
                  edit
                  color="white"
                  textColor="yellow"
                />
              </Link>
            ) : null}
            {actions.delete ? (
              <Button
                className={classes.actionButton}
                deleteButton
                onClick={() => {
                  setDeleteItemId(rawData?.data[index]?.id);
                  setModalOpen(true);
                }}
                color="white"
                textColor="danger"
                justIcon
              />
            ) : null}
          </div>
        ),
      };
    });
  }

  return (
    <div>
      <ConfirmModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setDeleteItemId(null);
          setTimeout(() => {
            setActiveScreen("question");
          }, 300);
        }}
        headerMessage={`Delete ${entityName}`}
        successHeaderText={"Success"}
        confirmMessage={`Are you sure you want to delete ${getName(
          rawData?.data?.find((row) => row.id === deleteItemId)
        )}?`}
        errorMessage={"An error has occurred"}
        successMessage={`${entityName} has been deleted successfully.`}
        confirmButtonText={"Delete"}
        confirmButtonColor="danger"
        cancelButtonText={"Cancel"}
        onConfirm={deleteItem}
        loading={isDeleteLoading}
        activeScreen={activeScreen}
        refreshOnSuccess
      />
      <div className={classes.topContainer}>
        <form onSubmit={handleSearch} className={classes.searchContainer}>
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: classes.searchInput,
              },
              onChange: (event) => setSearchQuery(event.target.value),
              value: searchQuery,
            }}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            className={searchButton}
            type="submit"
          >
            <Search
              className={classes.headerLinksSvg + " " + classes.searchIcon}
            />
          </Button>
        </form>
        <Link to={createUrl || `/admin/${path}/create`}>
          <Button create color="white" />
        </Link>
      </div>

      <GridContainer>
        {type === "table" && (
          <GridItem xs={12} sm={12} md={tableHead.length < 3 ? 6 : 12}>
            <Card mobileTransparent>
              <CardBody>
                <Loading loading={loadingData} style={{ height: "60vh" }}>
                  <Table
                    tableHeaderColor="primary"
                    tableHead={actions ? [...tableHead, " "] : tableHead}
                    tableData={tableData}
                    backendPagination
                    currentPage={currentPage}
                    paginate={paginate}
                    totalPosts={rawData?.count}
                    rowsPerPage={itemsPerPage}
                  />
                </Loading>
              </CardBody>
            </Card>
          </GridItem>
        )}
        {type === "grid" && (
          <GridItem xs={12} sm={12} md={12}>
            <GridList
              loadingData={loadingData}
              data={gridListData}
              CustomGridItem={GridListItem}
              backendPagination
              currentPage={currentPage}
              paginate={paginate}
              totalPosts={rawData?.count}
            />
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}

DataList.defaultProps = {
  getName: (row) => (row?.name?.en ? row?.name?.en : ""),
  getCardBody: () => [],
  getCardFooter: () => [],
  getImage: (el) => el?.image,
  type: "table",
};

DataList.propTypes = {
  dataResource: PropTypes.object,
  rawDataConvertor: PropTypes.func,
  getName: PropTypes.func,
  getCardBody: PropTypes.func,
  getCardFooter: PropTypes.func,
  getImage: PropTypes.func,
  entityName: PropTypes.string,
  path: PropTypes.string,
  createUrl: PropTypes.string,
  tableHead: PropTypes.arrayOf(PropTypes.string),
  actions: PropTypes.object,
  type: PropTypes.string,
};

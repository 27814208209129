import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Skeleton from "@material-ui/lab/Skeleton";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import styles from "./popupStyle";
import { flattenTranslationsObject } from "utils";
import { deepen } from "utils";

const useStyles = makeStyles(styles);

export default function Popup() {
  const classes = useStyles();

  const [sendRequest, isLoading] = useHttpClient();

  const [sendGetRequest, isGetLoading] = useHttpClient();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await sendGetRequest(api.getPopupInfo());
        if (response) {
          const temp = flattenTranslationsObject(response);
          Object.keys(temp).forEach((key) => {
            setValue(key, temp[key]);
          });
        }
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  const editHandler = (values) => async () => {
    try {
      const response = await sendRequest(api.updatePopupInfo(values));
      toast.success(`Popup edited successfully`);
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and try again");
  };

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, setValue } = form;

  const onSubmit = (e) => {
    e.preventDefault();

    let values = deepen(getValues(), "@");
    handleSubmit(editHandler(values), onError)();
  };

  return (
    <div>
      <Breadcrumbs />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <div className={classes.formHeader}>
                <EditIcon className={classes.icon} />

                {isGetLoading ? (
                  <Skeleton variant="rect" height={25} width={250} />
                ) : (
                  `Edit Popup`
                )}
              </div>
              <Loading loading={isGetLoading} style={{ height: "60vh" }}>
                <form
                  className={classes.form}
                  onSubmit={onSubmit}
                  autoComplete="off"
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <FormField
                        form={form}
                        type="text"
                        name="title@en"
                        label="Title (English)"
                      />
                      <FormField
                        form={form}
                        type="text"
                        name="description@en"
                        label="Description (English)"
                        options={{ multiline: true, rows: 4 }}
                      />
                      <FormField
                        form={form}
                        type="text"
                        name="buttonText@en"
                        label="Button Text (English)"
                      />
                      <FormField
                        form={form}
                        type="text"
                        name="redirectUrl"
                        label="Redirect to (Must start with http/https)"
                      />
                      <FormField
                        form={form}
                        type="image"
                        name="image"
                        label="Image"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1} />
                    <GridItem xs={12} sm={12} md={5}>
                      <FormField
                        form={form}
                        type="text"
                        name="title@ar"
                        label="Title (Arabic)"
                      />
                      <FormField
                        form={form}
                        type="text"
                        name="description@ar"
                        label="Description (Arabic)"
                        options={{ multiline: true, rows: 4 }}
                      />
                      <FormField
                        form={form}
                        type="text"
                        name="buttonText@ar"
                        label="Button Text (Arabic)"
                      />
                      <FormField
                        form={form}
                        type="switch"
                        name="enabled"
                        label="Should it be enabled?"
                      />
                    </GridItem>
                    <div className={classes.buttonContainer}>
                      <Button
                        color="primary"
                        type="submit"
                        loading={isLoading}
                        style={{ margin: "20px 0" }}
                        edit
                        loadFromRight
                      />
                    </div>
                  </GridContainer>
                </form>
              </Loading>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

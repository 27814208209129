const customFormStyle = (theme) => ({
  form: {
    padding: 20,
    paddingBottom: 0,
    [theme.breakpoints.down("xs")]: {
      padding: 20,
      paddingBottom: 0,
    },
  },
  buttonCointainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
});

export default customFormStyle;
